import { Module } from "vuex";
import { BankState, RootState } from "@/types/states";
import { Api } from "@/services/api";

export default {
  namespaced: true,
  state: {
    accounts: [],
    disabledAccounts: [],
    releves: "",
    availableBanks: [],
  },
  actions: {
    async loadAccounts({ commit }) {
      const { accounts, transactions } = await Api.getBankAccounts();
      commit("SET_ACCOUNTS", accounts);
      commit("SET_RELEVES", transactions);
    },
    async loadBanks({ commit }) {
      commit("SET_BANKS", await Api.getBanksList());
    },
  },
  mutations: {
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    SET_BANKS(state, banks) {
      state.availableBanks = banks;
    },
    SET_RELEVES(state, releves) {
      state.releves = releves;
    },
    TOGGLE_ACCOUNT(state, accountId: string) {
      if (state.disabledAccounts.includes(accountId))
        state.disabledAccounts = state.disabledAccounts.filter(
          (disabledId) => disabledId !== accountId
        );
      else state.disabledAccounts.push(accountId);
    },
  },
  getters: {
    enabledAmount(state): number {
      return state.accounts
        .filter((account) => !state.disabledAccounts.includes(account.id))
        .reduce((amount, account) => amount + account.balance, 0);
    },
    getReleves(state): string {
      return state.releves;
    },
  },
} as Module<BankState, RootState>;
