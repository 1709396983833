import _keyBy from "lodash.keyby";
import Axios from "@/services/axios";
import {
  Bank,
  BankAccount,
  BankTransaction,
  BankTransactions,
  BillingPlan,
  Company,
  Simulation,
  Tag,
  TagColor,
  Transaction,
  User,
} from "@/types/models";
import { momentFr as moment } from "@/utils/time";
import {
  BillingPlan as BillingPlanApi,
  Company as CompanyApi,
  Item,
  ItemPost,
  Profile,
  Simulation as SimulationApi,
  Tag as TagApi,
  BankData as BankDataApi,
} from "@/types/api";
import { now, toSfDate } from "@/utils/time";
import store from "@/store";
import { AxiosResponse } from "axios";

export const Api = {
  connectMyDSO: async ({
    login,
    password,
    url,
  }: {
    login: string;
    password: string;
    url: string;
  }): Promise<void> => {
    await Axios.put(
      `plugin/mydso/company${store.state.auth.companyId}/update`,
      {
        myDsoLogin: login,
        myDsoPassword: password,
        myDsoUrl: url,
      }
    );
  },
  createCompany: async (name: string): Promise<void> => {
    await Axios.post("company/create", { nom: name });
  },
  createSimulation: async (simulation: Simulation): Promise<number> =>
    (
      await Axios.post(
        `company${store.state.auth.companyId}/simulation/create`,
        {
          libelle: simulation.label,
        } as SimulationApi
      )
    ).data.id,
  createTag: async (tag: Tag): Promise<number> => {
    return (
      await Axios.post(`company${store.state.auth.companyId}/tag/create`, {
        libelle: tag.label,
        color: tag.colorId,
      } as TagApi)
    ).data.id;
  },
  createTransaction: async (transaction: Transaction): Promise<number> => {
    return (
      await Axios.post(`company${store.state.auth.companyId}/item/create`, {
        nom: transaction.label || "",
        prixTtc: transaction.amount,
        dateFacture: toSfDate(transaction.billedAt),
        datePrevisonnel: toSfDate(transaction.shouldPaidAt || now()),
        datePaiement: toSfDate(transaction.paidAt),
        ordre: 0,
        tag: transaction.tag,
        simulation: transaction.simulation,
      } as ItemPost)
    ).data.id;
  },
  deleteSimulation: async (simulation: Simulation): Promise<void> =>
    await Axios.delete(
      `company${store.state.auth.companyId}/simulation/${simulation.id}/delete`
    ),
  deleteTag: async (tag: Tag): Promise<void> => {
    await Axios.delete(
      `company${store.state.auth.companyId}/tag/${tag.id}/delete`
    );
  },
  deleteTransaction: async (transaction: Transaction): Promise<void> => {
    await Axios.delete(
      `company${store.state.auth.companyId}/item/${transaction.id}/delete`
    );
  },
  disconnectMyDso: async (): Promise<void> =>
    await Axios.get(
      `/plugin/mydso/company${store.state.auth.companyId}/disconnect`
    ),
  getInvoiceUrl: async (paymentId: number): Promise<{ url?: string }> =>
    (await Axios.get(`invoice/${paymentId}`)).data,
  getBankAccounts: async (): Promise<{
    accounts: BankAccount[];
    transactions: BankTransactions[];
  }> => {
    return Object.entries(
      ((
        await Axios.post("nordigen/accounts", {
          company: store.state.auth.companyId,
        })
      ).data || []) as Record<string, BankDataApi>
    )
      .filter(([_, account]) => !!account.account?.iban)
      .reduce(
        (out, [id, bank]) => ({
          accounts: [
            ...out.accounts,
            {
              id,
              name: bank.account.name,
              balance: parseFloat(
                bank.balances.find(
                  (b) =>
                    b.balanceType === "expected" ||
                    b.balanceType === "interimAvailable" ||
                    b.balanceType === "closingBooked" ||
                    b.balanceType === "information"
                )?.balanceAmount.amount || "0"
              ),
              iban: bank.account.iban,
              lastUpdatedAt: moment(
                bank.balances.find((b) => b.balanceType === "expected")
                  ?.referenceDate
              ),
              currency: bank.account.currency,
            } as BankAccount,
          ],
          transactions: [
            ...out.transactions,
            {
              name: bank.account.name,
              transactions: {
                last_date: Object.values(bank.transactions)
                  .reduce(
                    (allTransactions, transactions) => [
                      ...allTransactions,
                      ...transactions,
                    ],
                    []
                  )
                  .sort((a, b) => moment(a.valueDate).diff(moment(b.valueDate)))
                  .reverse()[0]?.valueDate,
                first_date: Object.values(bank.transactions)
                  .reduce(
                    (allTransactions, transactions) => [
                      ...allTransactions,
                      ...transactions,
                    ],
                    []
                  )
                  .sort((a, b) =>
                    moment(a.valueDate).diff(moment(b.valueDate))
                  )[0]?.valueDate,
                total: Object.values(bank.transactions).reduce(
                  (allTransactions, transactions) => [
                    ...allTransactions,
                    ...transactions,
                  ],
                  []
                ).length,
                transactions: Object.values(bank.transactions)
                  .reduce(
                    (allTransactions, transactions) => [
                      ...allTransactions,
                      ...transactions,
                    ],
                    []
                  )
                  .map(
                    (transaction) =>
                      ({
                        id: transaction.transactionId,
                        date: transaction.valueDate,
                        value: parseFloat(transaction.transactionAmount.amount),
                        original_wording:
                          transaction.remittanceInformationUnstructured ||
                          transaction.remittanceInformationUnstructuredArray?.join(
                            " - "
                          ) ||
                          transaction.creditorName,
                        stemmed_wording:
                          transaction.remittanceInformationUnstructured ||
                          transaction.remittanceInformationUnstructuredArray?.join(
                            " - "
                          ) ||
                          transaction.creditorName,
                        formatted_value: transaction.transactionAmount.amount,
                      } as BankTransaction)
                  ),
              },
            } as BankTransactions,
          ],
        }),
        { accounts: [], transactions: [] } as {
          accounts: BankAccount[];
          transactions: BankTransactions[];
        }
      );
  },
  getBanksList: async (): Promise<Bank[]> => {
    return [
      ...(process.env.NODE_ENV !== "production"
        ? [
            {
              name: "__ Banque de test __",
              id: "SANDBOXFINANCE_SFIN0000",
              logo: "/img/logo.svg",
            } as Bank,
          ]
        : []),
      ...((await Axios.get("nordigen/bank-list")).data?.message || []),
    ] as Bank[];
  },
  getBillingPlans: async (): Promise<BillingPlan[]> =>
    (
      (Object.values((await Axios.get("plan"))?.data?.message) ||
        []) as BillingPlanApi[]
    ).map(
      (plan: BillingPlanApi) =>
        ({
          id: plan.id,
          name: plan.nom,
          price: plan.prix,
          duration: plan.duree,
          stripeId: plan.stripeId,
        } as BillingPlan)
    ),
  getBIURL: async (redirectUri: string): Promise<string> =>
    (await Axios.post("bi/connect", { callback: redirectUri }))?.data?.message,
  getBankURL: async (
    redirectUri: string,
    institution_id: string
  ): Promise<string> =>
    (
      await Axios.post("nordigen/connect", {
        callback: redirectUri,
        company: store.state.auth.companyId,
        institution_id,
      })
    )?.data?.message,
  getTags: async (): Promise<{ [key: number]: Tag }> => {
    return _keyBy(
      (
        await Axios.get(`company${store.state.auth.companyId}/tag/read`)
      ).data.message.map((tag: TagApi) => ({
        id: tag.id,
        label: tag.libelle,
        colorId: tag.color,
      })),
      (t: Tag) => t.id
    );
  },
  getTagColors: async (): Promise<{ [key: number]: TagColor }> => {
    return Object.entries((await Axios.get("tag/color")).data.message).reduce(
      (colors, [id, color]) =>
        Object.assign(colors, { [id]: { id: parseInt(id), color } }),
      {}
    );
  },
  getTransactions: async (): Promise<Transaction[]> => {
    return (
      await Axios.get(`company${store.state.auth.companyId}/item/read`)
    ).data.message.map(
      (item: Item) =>
        new Transaction(
          item.id,
          item.nom,
          item.prixTtc,
          moment(item.datePrevisonnel.date),
          item.dateFacture && moment(item.dateFacture.date),
          item.datePaiement && moment(item.datePaiement.date),
          item.isPaye,
          item.tag,
          item.simulation,
          item.ordre,
          item.commentaire,
          item.locked
        )
    );
  },
  getUserProfile: async (): Promise<Profile> =>
    (await Axios.get("user/read"))?.data?.message,
  initiateStripePortal: async (): Promise<string> =>
    (
      await Axios.post("/stripe/url", {
        company: store.state.auth.companyId,
        callback:
          (process.env.VUE_APP_BASE_URL ||
            "https://alticash.app/settings/billing") + "settings/billing",
      })
    ).data?.message,
  intentPlanPayment: async (): Promise<string> =>
    (
      await Axios.post(`/stripe/checkout`, {
        company: store.state.auth.companyId,
        callbackSuccess:
          (process.env.VUE_APP_BASE_URL || "https://alticash.app/") +
          "return/stripe/",
        callbackCancel:
          (process.env.VUE_APP_BASE_URL || "https://alticash.app/") +
          "settings/billing",
      })
    )?.data?.message,
  persistBIToken: async ({
    id,
    code,
    company,
  }: {
    id: string;
    code: string;
    company: number;
  }): Promise<AxiosResponse> =>
    await Axios.put(`bi/return?connection_id=${id}&code=${code}`, {
      company,
    }),
  updateTag: async (tag: Tag): Promise<void> =>
    await Axios.put(
      `company${store.state.auth.companyId}/tag/${tag.id}/update`,
      {
        color: tag.colorId,
        libelle: tag.label,
      } as TagApi
    ),
  refreshMyDso: async (): Promise<void> =>
    await Axios.get(
      `/plugin/mydso/company${store.state.auth.companyId}/refresh`
    ),
  register: async ({
    email,
    password,
    firstname,
    lastname,
  }: {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
  }): Promise<void> => {
    await Axios.post("user/create", {
      email,
      password,
      firstName: firstname,
      lastName: lastname,
    });
  },
  resetPassword: (token: string, password: string): Promise<AxiosResponse> =>
    Axios.post("user/password/change", { token, password }),
  sendResetPasswordLink: (email: string): Promise<AxiosResponse> =>
    Axios.post("user/password/request", { username: email }),
  subscribeToPlan: async (planStripeId: string): Promise<boolean> =>
    (
      await Axios.post("stripe/subscription", {
        company: store.state.auth.companyId,
        stripeId: planStripeId,
      })
    ).data?.code === 200,
  updateCompanyProfile: async (company: Company): Promise<boolean> =>
    Axios.put("company/${store.state.auth.companyId}/update", {
      nom: company.name,
      addresse: company.address,
      codePostal: company.zip,
      ville: company.city,
      siret: company.siret,
      telephone: company.phoneNumber,
    } as CompanyApi),
  updateSimulation: async (simulation: Simulation): Promise<void> =>
    await Axios.put(
      `company${store.state.auth.companyId}/simulation/${simulation.id}/update`,
      { libelle: simulation.label } as SimulationApi
    ),
  updateTransaction(transaction: Transaction): Promise<boolean> {
    return Axios.put(
      `company${store.state.auth.companyId}/item/${transaction.id}/update`,
      {
        nom: transaction.label || "",
        prixTtc: transaction.amount,
        dateFacture: toSfDate(transaction.billedAt),
        datePrevisonnel: toSfDate(transaction.shouldPaidAt || now()),
        datePaiement: toSfDate(transaction.paidAt),
        isPaye: transaction.paid,
        tag: transaction.tag || null,
        simulation: transaction.simulation || false,
        commentaire: transaction.notes,
      } as ItemPost
    );
  },
  updateUserBalance: async (balance: number): Promise<boolean> =>
    Axios.put(`company/${store.state.auth.companyId}/update`, {
      solde: balance,
    }),
  updateUserPassword: async (password: string): Promise<boolean> =>
    Axios.put("user/update", { password }),
  updateUserProfile: async (user: User): Promise<boolean> =>
    Axios.put("user/update", user),
  updateThresholds: async ({
    lowBalance,
    maxOverdraft,
  }: {
    lowBalance: number;
    maxOverdraft: number;
  }): Promise<boolean> =>
    Axios.put(`company/${store.state.auth.companyId}/update`, {
      decouvert: maxOverdraft,
      pointMort: lowBalance,
    }),
};
