import Vue from "vue";
import { Module } from "vuex";
import { RootState, UiState } from "@/types/states";
import { Transaction } from "@/types/models";

export default {
  namespaced: true,
  state: {
    exportModalShown: false,
    importModalShown: false,
    scenarioModalShown: false,
    timelineSettings: {
      expendedColumn: null,
      graphCollapsed: false,
      transactionsCollapsed: false,
    },
    transactionModalTransaction: null,
    transactionOrWizardModalShown: false,
    welcomeModalShown: false,
    tutoModalShown: false,
    tourShown: false,
    upgradeModalShown: false,
    relevesModalShown: false,
    relevesSidebarShown: false,
  },
  mutations: {
    OPEN_TRANSACTION_MODAL(state, { transaction } = {}) {
      if (!state.timelineSettings.transactionsCollapsed || !transaction)
        state.transactionModalTransaction = transaction || new Transaction();
    },
    CLOSE_TRANSACTION_MODAL(state) {
      state.transactionModalTransaction = null;
    },
    EXPEND_COLUMN(state, columnDateStart) {
      state.timelineSettings.expendedColumn = columnDateStart;
    },
    TOGGLE_COLLAPSE_TRANSACTION(state) {
      Vue.set(
        state.timelineSettings,
        "transactionsCollapsed",
        !state.timelineSettings.transactionsCollapsed
      );
    },
    TOGGLE_COLLAPSE_GRAPH(state) {
      Vue.set(
        state.timelineSettings,
        "graphCollapsed",
        !state.timelineSettings.graphCollapsed
      );
    },
    TOGGLE_RELEVES_SIDEBAR(state) {
      state.relevesSidebarShown = !state.relevesSidebarShown;
    },
    OPEN_EXPORT_MODAL(state) {
      state.exportModalShown = true;
    },
    CLOSE_EXPORT_MODAL(state) {
      state.exportModalShown = false;
    },
    OPEN_IMPORT_MODAL(state) {
      state.importModalShown = true;
    },
    CLOSE_IMPORT_MODAL(state) {
      state.importModalShown = false;
    },
    OPEN_SCENARIO_MODAL(state) {
      state.scenarioModalShown = true;
    },
    CLOSE_SCENARIO_MODAL(state) {
      state.scenarioModalShown = false;
    },
    OPEN_TRANSACTION_WIZARD_MODAL(state) {
      state.transactionOrWizardModalShown = true;
    },
    CLOSE_TRANSACTION_WIZARD_MODAL(state) {
      state.transactionOrWizardModalShown = false;
    },
    OPEN_WELCOME_MODAL(state) {
      state.welcomeModalShown = true;
    },
    CLOSE_WELCOME_MODAL(state) {
      state.welcomeModalShown = false;
    },
    OPEN_TUTO_MODAL(state) {
      state.tutoModalShown = true;
    },
    CLOSE_TUTO_MODAL(state) {
      state.tutoModalShown = false;
    },
    OPEN_TOUR(state) {
      state.tourShown = true;
    },
    CLOSE_TOUR(state) {
      state.tourShown = false;
    },
    OPEN_UPGRADE_MODAL(state) {
      state.upgradeModalShown = true;
    },
    CLOSE_UPGRADE_MODAL(state) {
      state.upgradeModalShown = false;
    },
    OPEN_RELEVES_MODAL(state) {
      state.relevesModalShown = true;
    },
    CLOSE_RELEVES_MODAL(state) {
      state.relevesModalShown = false;
    },
  },
} as Module<UiState, RootState>;
