import { Module } from "vuex";
import { RootState, SettingsState } from "@/types/states";
import { Api } from "@/services/api";

export default {
  namespaced: true,
  state: {
    lowBalanceAlert: 1000000,
    maxOverdraft: -500000,
  },
  actions: {
    async updateThresholds({ dispatch }, { maxOverdraft, lowBalance }) {
      await Api.updateThresholds({ lowBalance, maxOverdraft });
      dispatch("auth/loadProfile", null, { root: true });
    },
  },
  mutations: {
    SET_SETTINGS(state, settings: SettingsState) {
      Object.assign(state, settings);
    },
  },
} as Module<SettingsState, RootState>;
