import { Check, Clock, Icon, Warning, X } from "@/utils/icons";
import { now } from "@/utils/time";
import moment, { Moment, unitOfTime } from "moment";
import { getLockerLabel } from "@/utils/transactions";

export type Bank = {
  id: string;
  name: string;
  bic: string;
  logo: string;
};

export type BankAccount = {
  id: string;
  name: string;
  balance: number;
  iban?: string;
  lastUpdatedAt: Moment;
  currency: Currency;
};

export type BankTransactions = {
  name: string;
  transactions: {
    first_date: string;
    last_date: string;
    transactions: BankTransaction[];
    total: number;
  };
};

export type BankTransaction = {
  id: string;
  date: string;
  value: number;
  original_wording: string;
  stemmed_wording: string;
  formatted_value: string;
};

export type ChartData = { labels: string[]; data: number[] };

export type Currency = "EUR" | "USD";

export type Company = {
  id: number;
  name: string;
  address: string;
  zip: string;
  city: string;
  phoneNumber: string;
  siret: string;
  currentPlanId: number;
  lastPayment?: Payment;
  payments: Payment[];
  plugins: Record<Plugins, Plugin>;
};

export type BillingPlan = {
  id: number;
  name: string;
  price: number;
  duration: string;
  stripeId: string;
};

export type BillingStatus = {
  subscriptionPaid: boolean;
  subscriptionFree: boolean;
  subscriptionEndDate: Moment;
};

export type Payment = {
  id: number;
  reference: string;
  date: Moment;
  amount: number;
  planId: number;
};

export type Plugin = {
  credentials: {
    login?: string;
    url?: string;
  };
};

export type Plugins = "mydso";

export type Scenario = {
  id: string;
  name: string;
  startDate: Moment;
  color: string;
  lines: ScenarioLine[];
};

export type ScenarioLine = {
  label: string;
  startDate: Moment;
  incomeAmount?: number;
  outcomeAmount?: number;
  repeatCount: number;
  repeatScale: unitOfTime.Base | unitOfTime._quarter;
  increasePercentage: number;
  increaseEach: unitOfTime.Base | unitOfTime._quarter | "never";
};

export type Simulation = {
  id?: number;
  label: string;
};

export type Tag = {
  id?: number;
  label: string;
  colorId?: number;
  color?: string;
};

export type TagColor = {
  id: number;
  color: string;
};

export class Transaction {
  public id = 0;
  public label?: string;
  public amount = 0;
  public shouldPaidAt = moment().startOf("day").add(30, "days");
  public billedAt?: Moment;
  public paidAt?: Moment;
  public paid = false;
  public tag?: number;
  public simulation?: number;
  public order?: number;
  public notes?: string;
  public lock?: string;

  constructor(
    id?: number,
    label?: string,
    amount?: number,
    shouldPaidAt?: Moment,
    billedAt?: Moment,
    paidAt?: Moment,
    paid?: boolean,
    tag?: number,
    simulation?: number,
    order?: number,
    notes?: string,
    lock?: string
  ) {
    if (id) this.id = id;
    this.label = label;
    if (amount) this.amount = amount;
    this.billedAt = billedAt;
    if (shouldPaidAt) this.shouldPaidAt = shouldPaidAt;
    this.paidAt = paidAt;
    if (paid) this.paid = paid;
    if (tag) this.tag = tag;
    if (simulation) this.simulation = simulation;
    if (order) this.order = order;
    if (notes) this.notes = notes;
    if (lock) this.lock = lock;
  }

  get locked(): boolean {
    return !!this.lock;
  }

  get locker(): string {
    return getLockerLabel(this.lock);
  }

  get status(): TransactionStatus {
    return this.getStatus();
  }

  static from(
    transaction:
      | Transaction
      | Omit<Transaction, "getStatus" | "locked" | "locker" | "status">
  ): Transaction {
    return new Transaction(
      transaction.id,
      transaction.label,
      transaction.amount,
      transaction.shouldPaidAt,
      transaction.billedAt,
      transaction.paidAt,
      transaction.paid,
      transaction.tag,
      transaction.simulation,
      transaction.order,
      transaction.notes,
      transaction.lock
    );
  }

  getStatus(): TransactionStatus {
    return this.paid
      ? TRANSACTION_PAID
      : !this.billedAt
      ? TRANSACTION_TO_BILL
      : this.paidAt?.isBefore(now().startOf("day"))
      ? TRANSACTION_LATE
      : TRANSACTION_TO_BE_PAID;
  }
}

export type TransactionFilters = {
  lock: string[];
  simulation: number[];
  status: TransactionStatus[];
  tag: number[];
};

export type TransactionStatus = {
  icon: Icon;
  color: string;
  label: string;
};

export const TRANSACTION_PAID: TransactionStatus = {
  icon: Check,
  color: "text-green-400",
  label: "Réglé",
};

export const TRANSACTION_TO_BILL: TransactionStatus = {
  icon: Warning,
  color: "text-yellow-500",
  label: "A facturer",
};

export const TRANSACTION_LATE: TransactionStatus = {
  icon: X,
  color: "text-red-700",
  label: "En retard",
};

export const TRANSACTION_TO_BE_PAID: TransactionStatus = {
  icon: Clock,
  color: "text-blue-500",
  label: "A échoir",
};

export const TransactionStatuses = [
  TRANSACTION_PAID,
  TRANSACTION_TO_BILL,
  TRANSACTION_LATE,
  TRANSACTION_TO_BE_PAID,
];

export type User = {
  email: string;
  firstName: string;
  lastName: string;
};
