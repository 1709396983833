import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./style/base.scss";
import VAutofocus from "@vuejs-tips/v-autofocus";
import VAutoWidth from "vue-input-autowidth";
import VueDragScroll from "vue-dragscroll";
import VueNotification from "vue-notification";
import VShortKey from "vue-shortkey";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false;

Vue.use(VAutofocus);
Vue.use(VAutoWidth);
Vue.use(VueDragScroll);
Vue.use(VueNotification, { componentName: "Notifications" });
Vue.use(VShortKey, { prevent: ["input", "textarea"] });

if (["staging", "production"].includes(process.env.NODE_ENV || "dev"))
  Sentry.init({
    dsn: "https://b6ac26510a04431f992485d2c666915f@o475727.ingest.sentry.io/5514095",
    Vue,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
