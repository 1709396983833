import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";
import plugins from "./plugins";
import { RootState } from "@/types/states";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules,
  plugins,
});
