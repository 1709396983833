import store from "@/store";
import { noop } from "vue-class-component/lib/util";
import { AxiosError } from "axios";

const errors = [
  {
    apiMessage: "Compte désactivé",
    expired: false,
    error: "accountDisabled",
    logout: true,
  },
  {
    apiMessage: "Invalid credentials.",
    expired: false,
    error: "invalidCredentials",
  },
  {
    apiMessage: "Unauthorized",
    expired: true,
    error: "sessionExpired",
    logout: true,
  },
  {
    apiMessage: "Expired JWT Token",
    expired: true,
    error: "sessionExpired",
    logout: true,
  },
];

export const catchNetworkError = (error: AxiosError): Promise<void> => {
  if (!error.response) {
    store.commit("errors/SET", "networkError");
  }

  return Promise.reject(error);
};

export const catchUnauthorized = async (error: AxiosError): Promise<void> => {
  if (error.response && error.response.status === 401) {
    const errorInfo = errors.find(
      (e) =>
        e.apiMessage === (error.response?.data as { message: string })?.message
    );

    store.commit("errors/RESET", "*");
    store.commit("errors/SET", errorInfo?.error || "networkError");
    if (errorInfo?.logout)
      await store.dispatch("auth/logout", {
        expired: errorInfo?.expired || false,
      });
  }

  return Promise.reject(error);
};

export const catchAll = (): void => {
  noop();
};
