import Axios from "axios";
import { tokenInjector } from "@/services/axios/interceptors/request";
import {
  catchAll,
  catchNetworkError,
  catchUnauthorized,
} from "@/services/axios/interceptors/response";

Axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT || "";

Axios.interceptors.request.use(tokenInjector);
Axios.interceptors.response.use((response) => response, catchNetworkError);
Axios.interceptors.response.use((response) => response, catchUnauthorized);
Axios.interceptors.response.use((response) => response, catchAll);

export default Axios;
