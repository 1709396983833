import { Module } from "vuex";
import { BillingState, RootState } from "@/types/states";
import { momentFr as moment } from "@/utils/time";
import { BillingStatus } from "@/types/models";
import { Api } from "@/services/api";

export default {
  namespaced: true,
  state: {
    plans: [],
    subscriptionEndDate: undefined,
    subscriptionFree: false,
    subscriptionPaid: false,
  },
  actions: {
    async loadPlans({ commit }) {
      commit("SET_PLANS", await Api.getBillingPlans());
    },
  },
  mutations: {
    SET_BILLING_STATUS(state, billingStatus: BillingStatus) {
      state.subscriptionEndDate = billingStatus.subscriptionEndDate;
      state.subscriptionFree = billingStatus.subscriptionFree;
      state.subscriptionPaid = billingStatus.subscriptionPaid;
    },
    SET_PLANS(state, plans) {
      state.plans = plans;
    },
  },
  getters: {
    showBillingBanner(state, getters) {
      return (
        !getters.isSubscriptionFreeForever ||
        (state.subscriptionEndDate &&
          moment().add(14, "days").isSameOrAfter(state.subscriptionEndDate))
      );
    },
    daysToEnd(state) {
      return (state.subscriptionEndDate || moment()).diff(
        moment().startOf("day"),
        "days"
      );
    },
    isSubscriptionExpired(state) {
      return (state.subscriptionEndDate || moment()).isBefore(moment());
    },
    isSubscriptionFreeForever(state, getters) {
      return state.subscriptionFree && getters.daysToEnd > 365;
    },
  },
} as Module<BillingState, RootState>;
