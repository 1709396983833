import {
  Currency,
  Transaction,
  TransactionFilters,
  TransactionStatuses,
} from "@/types/models";
import { min, Moment } from "moment";
import Vue from "vue";
import { RootState, TransactionsState } from "@/types/states";
import {
  DailyBalance,
  TimelineData,
  TimelineDirection,
  TimelineGraphBounds,
  TimelineScale,
  TimelineSortCriteria,
} from "@/types/timeline";
import { Module } from "vuex";
import { now } from "@/utils/time";
import { roundPower10, transactionsBalanceReducer } from "@/utils/transactions";
import _pickBy from "lodash.pickby";
import { Api } from "@/services/api";

const allFilters = (): TransactionFilters => ({
  lock: [],
  simulation: [],
  status: TransactionStatuses,
  tag: [],
});

const getInitialState = (): TransactionsState => ({
  activeColumnDate: undefined,
  amountConstraints: {
    min: null,
    max: null,
  },
  countSimulation: true,
  currency: "EUR",
  dailyBalances: [],
  filters: allFilters(),
  initialized: false,
  sortCriteria: "date",
  timelineStart: undefined,
  timelineEnd: undefined,
  timelineScale: "week",
  transactions: {},
  wantClone: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions: {
    async delete({ commit }, transaction: Transaction) {
      await Api.deleteTransaction(transaction);
      commit("DELETE", transaction.id);
      Vue.notify({
        group: "app",
        title: "Votre transaction à été supprimée",
        type: "success",
      });
    },
    async extend({ commit, dispatch }, direction: TimelineDirection) {
      commit("EXTEND_TIMELINE", direction);

      await dispatch("loadTransactions");
    },
    async init({ commit, dispatch, state }) {
      if (state.initialized) return;

      dispatch("setTimelineBounds");
      await dispatch("auth/loadProfile", null, { root: true });
      await dispatch("loadTransactions");
      commit("SET_INITIALIZED");
    },
    async loadTransactions({ commit }) {
      const transactions = await Api.getTransactions();
      commit("ADD_TRANSACTIONS", transactions);
    },
    async reset({ commit, dispatch }) {
      commit("SET_INITIALIZED", false);
      commit("RESET_STATE");
      await dispatch("init");
    },
    async setBalance({ dispatch }, balance: number) {
      await Api.updateUserBalance(balance);
      await dispatch("auth/loadProfile", null, { root: true });
    },
    setTimelineBounds({ commit, state }) {
      commit("SET_DATE_INTERVAL", {
        start: now()
          .subtract(2, state.timelineScale)
          .startOf(
            state.timelineScale === "week" ? "isoWeek" : state.timelineScale
          ),
        end: now()
          .add(15, state.timelineScale)
          .endOf(
            state.timelineScale === "week" ? "isoWeek" : state.timelineScale
          ),
      });
      if (!state.activeColumnDate) {
        commit("SET_ACTIVE_DATE", state.timelineStart);
      }
    },
    async updateDate(
      { dispatch },
      { transaction, date }: { transaction: Transaction; date: Moment }
    ) {
      transaction.shouldPaidAt = date;
      dispatch("upsert", transaction);
    },
    async upsert({ commit }, transaction: Transaction) {
      // Newly created
      if (transaction.id === 0) {
        transaction.id = await Api.createTransaction(transaction);
      } else {
        await Api.updateTransaction(transaction);
      }
      commit("UPSERT", transaction);
      Vue.notify({
        group: "app",
        title: "Votre transaction à été enregistrée",
        type: "success",
      });
    },
  },
  mutations: {
    ADD_TRANSACTIONS(state, transactions: Transaction[]) {
      transactions.forEach((t) => Vue.set(state.transactions, t.id, t));
    },
    CLEAR_TRANSACTIONS(state) {
      Vue.set(state, "transactions", {});
    },
    DELETE(state, transactionId: number) {
      Vue.delete(state.transactions, transactionId);
    },
    EXTEND_TIMELINE(state, direction: TimelineDirection) {
      if (!state.timelineStart || !state.timelineEnd) {
        return;
      }

      if (direction === "past") {
        state.timelineStart = state.timelineStart
          .clone()
          .subtract(5, state.timelineScale);
        state.timelineEnd = state.timelineEnd
          .clone()
          .subtract(5, state.timelineScale);
      }
      if (direction === "future") {
        /** We must keep today as reference to compute all balances in the future */
        state.timelineStart = min(
          now().startOf(state.timelineScale),
          state.timelineStart.clone().add(5, state.timelineScale)
        );
        state.timelineEnd = state.timelineEnd
          .clone()
          .add(5, state.timelineScale);
      }
    },
    RESET_FILTERS(state) {
      Object.assign(state.filters, allFilters());
      state.amountConstraints = {
        min: null,
        max: null,
      };
    },
    RESET_STATE(state) {
      Object.assign(state, getInitialState());
    },
    SET_ACTIVE_DATE(state, date: Moment) {
      state.activeColumnDate = date;
    },
    SET_AMOUNT_CONSTRAINT(
      state,
      { key, value }: { key: "min" | "max"; value: number }
    ) {
      state.amountConstraints[key] = value;
    },
    SET_CURRENCY(state, currency: Currency) {
      state.currency = currency;
    },
    SET_DATE_INTERVAL(state, { start, end }: { start: Moment; end: Moment }) {
      Vue.set(state, "timelineStart", start);
      Vue.set(state, "timelineEnd", end);
    },
    SET_DAILY_BALANCES(state, balances: DailyBalance[]) {
      Vue.set(state, "dailyBalances", balances);
    },
    SET_INITIALIZED(state, initialized = true) {
      state.initialized = initialized;
    },
    SET_SCALE(state, scale: TimelineScale) {
      state.timelineScale = scale;
      state.timelineStart = (state.activeColumnDate || state.timelineStart)
        ?.clone()
        .startOf(scale);
      state.timelineEnd = state.timelineStart
        ?.clone()
        .add(20, scale)
        .endOf(scale);
    },
    SET_SORT_CRITERIA(state, criteria: TimelineSortCriteria) {
      state.sortCriteria = criteria;
    },
    SET_WANT_CLONE(state, wantClone: boolean) {
      state.wantClone = wantClone;
    },
    TOGGLE_COUNT_SIMULATION(state) {
      state.countSimulation = !state.countSimulation;
    },
    TOGGLE_FILTER(
      state,
      {
        key,
        value,
        checked,
      }: {
        key: keyof TransactionFilters;
        value: never;
        checked: boolean;
      }
    ) {
      if (checked) {
        if ((state.filters[key] as never[]).indexOf(value) < 0)
          state.filters[key].push(value);
      } else
        state.filters[key] = (state.filters[key] as never[]).filter(
          (v) => v !== value
        );
    },
    UPSERT(state, transaction: Transaction) {
      Vue.set(
        state.transactions,
        transaction.id,
        Transaction.from(transaction)
      );
    },
  },
  getters: {
    balanceAt:
      (state, getters, rootState, rootGetters) =>
      (date: Moment): DailyBalance => {
        const nextIndex = state.dailyBalances.findIndex((db) =>
          db.date.isAfter(date.startOf("day"))
        );

        const balance = state.dailyBalances[
          (nextIndex >= 0 ? nextIndex : state.dailyBalances.length) - 1
        ] || { balance: 0, date };

        return {
          ...balance,
          balance: rootState.bank.accounts.length
            ? rootGetters["bank/enabledAmount"] * 100
            : balance.balance,
        };
      },
    columns(state, getters, rootState: RootState): TimelineData[] {
      const columns: TimelineData[] = [];

      if (!state.timelineStart) {
        return [];
      }

      /*
       * As we clone timelineStart to break reference when modifying (Moment is mutable),
       * VueJs doesn't detect changes precisely because we break reference
       */
      /* eslint-disable-next-line */
      const forceRecompute = state.timelineStart;
      const timelineStart = state.timelineStart.clone();
      let initialBalance = getters.balanceAt(state.timelineStart).balance;

      do {
        const periodStart = timelineStart.clone();
        const periodEnd = timelineStart
          .add(1, state.timelineScale)
          .clone()
          .subtract(1, "second");
        const transactions = Object.values(state.transactions)
          .filter((t) =>
            t.shouldPaidAt.isBetween(periodStart, periodEnd, "day", "[]")
          )
          .sort((a: Transaction, b: Transaction) => {
            if (state.sortCriteria === "date") {
              return a.shouldPaidAt.diff(b.shouldPaidAt);
            }

            if (state.sortCriteria === "amount") {
              return Math.abs(b.amount) - Math.abs(a.amount);
            }

            if (state.sortCriteria === "alpha") {
              return (a.label || "z").localeCompare(b.label || "z");
            }

            if (state.sortCriteria === "label") {
              if (a.tag && b.tag)
                return (rootState.tags.tags[a.tag]?.label || "a").localeCompare(
                  rootState.tags.tags[b.tag]?.label || "b"
                );

              return a.tag ? -1 : 1;
            }

            return 0;
          });
        const finalBalance = periodEnd.isBefore(now(), "day")
          ? /* Completely past */
            getters.balanceAt(periodEnd).balance
          : periodStart.isSameOrBefore(now(), "day")
          ? /* Current columns contains today */
            transactions
              .filter((t) => !t.paid)
              .filter((t) => t.shouldPaidAt.isSameOrAfter(now(), "day"))
              .filter((t) => state.countSimulation || !t.simulation)
              .filter((t) =>
                (
                  Object.entries(state.filters) as [
                    keyof TransactionFilters,
                    // eslint-disable-next-line
                    any
                  ][]
                ).reduce(
                  (keep: boolean, [attribute, values]) =>
                    keep && (!t[attribute] || values.includes(t[attribute])),
                  true
                )
              )
              .reduce(
                transactionsBalanceReducer,
                getters.balanceAt(now()).balance
              )
          : /* Completely in the future */
            transactions
              .filter((t) => !t.paid)
              .filter((t) => state.countSimulation || !t.simulation)
              .filter((t) =>
                (
                  Object.entries(state.filters) as [
                    keyof TransactionFilters,
                    // eslint-disable-next-line
                    any
                  ][]
                ).reduce(
                  (keep: boolean, [attribute, values]) =>
                    keep &&
                    (values.includes(-1) ||
                      values.includes("*") ||
                      !t[attribute] ||
                      values.includes(t[attribute])),
                  true
                )
              )
              .reduce(transactionsBalanceReducer, initialBalance);

        columns.push({
          periodStart,
          periodEnd,
          initialBalance,
          finalBalance,
          transactions: transactions
            .filter((t) => {
              if (
                state.amountConstraints.min !== null &&
                t.amount < state.amountConstraints.min
              )
                return false;
              if (
                state.amountConstraints.max !== null &&
                t.amount > state.amountConstraints.max
              )
                return false;

              return true;
            })
            .filter((t) =>
              (
                Object.entries(state.filters) as [
                  keyof TransactionFilters,
                  // eslint-disable-next-line
                  any
                ][]
              ).reduce(
                (keep: boolean, [attribute, values]) =>
                  keep &&
                  (values.includes(-1) ||
                    !t[attribute] ||
                    values.includes(t[attribute])),
                true
              )
            ),
        });

        initialBalance = finalBalance;
      } while (timelineStart.isBefore(state.timelineEnd));

      return columns;
    },
    currentBalance(state, getters): number {
      return getters.balanceAt(now());
    },
    graduations(state, getters, rootState): number[] {
      const graduations = [];

      const { min, max } = getters.graphBounds;
      const { lowBalanceAlert, maxOverdraft } = rootState.settings;

      if (max > lowBalanceAlert + 0.1 * (max - min))
        graduations.push(roundPower10(max));
      if (min < maxOverdraft - 0.1 * (max - min))
        graduations.push(roundPower10(min));

      if ((max - lowBalanceAlert) / (max - min) >= 0.5)
        graduations.push(roundPower10((max - lowBalanceAlert) / 2));
      if ((maxOverdraft - min) / (max - min) >= 0.5)
        graduations.push(roundPower10(-(maxOverdraft - min) / 2));

      return graduations;
    },
    hasPastTransactions(state, getters): boolean {
      return Object.values(getters.pastTransactions).length > 0;
    },
    isFiltered(state): boolean {
      return state.filters !== allFilters();
    },
    minBalance(state, getters): number {
      return Object.values(getters.columns).length
        ? (Object.values(getters.columns) as TimelineData[]).reduce(
            (previousColumn: TimelineData, currentColumn: TimelineData) =>
              previousColumn.finalBalance > currentColumn.finalBalance
                ? currentColumn
                : previousColumn
          ).finalBalance
        : 0;
    },
    maxBalance(state, getters): number {
      return Object.values(getters.columns).length
        ? (Object.values(getters.columns) as TimelineData[]).reduce(
            (previousColumn: TimelineData, currentColumn: TimelineData) =>
              previousColumn.finalBalance < currentColumn.finalBalance
                ? currentColumn
                : previousColumn
          ).finalBalance
        : 0;
    },
    pastTransactions(state): { [key: number]: Transaction } {
      return _pickBy(
        state.transactions,
        (t: Transaction) =>
          t.shouldPaidAt.isBefore(now().startOf("day")) &&
          !t.paid &&
          t.amount !== 0
      );
    },
    graphBounds(state, getters, rootState): TimelineGraphBounds {
      const { minBalance, maxBalance } = getters;

      return {
        min:
          Math.floor(
            Math.min(
              -0.1 * maxBalance,
              minBalance,
              1.2 * rootState.settings.maxOverdraft
            ) / 100000
          ) * 100000,
        max: Math.max(
          Math.ceil(maxBalance / 100000) * 100000,
          1.2 * rootState.settings.lowBalanceAlert
        ),
      };
    },
  },
} as Module<TransactionsState, RootState>;
