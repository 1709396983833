import auth from "./auth";
import bank from "./bank";
import billing from "./billing";
import dashboard from "./dashboard";
import errors from "./errors";
import simulations from "./simulations";
import settings from "./settings";
import tags from "./tags";
import transactions from "./transactions";
import ui from "./ui";

export default {
  auth,
  bank,
  billing,
  dashboard,
  errors,
  simulations,
  settings,
  tags,
  transactions,
  ui,
};
