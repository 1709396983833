import { Module } from "vuex";
import { RootState, SimulationsState } from "@/types/states";
import { Api } from "@/services/api";
import { Simulation } from "@/types/models";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    simulations: {},
  },
  actions: {
    async create(
      { commit, rootState },
      simulation: Simulation
    ): Promise<Simulation> {
      simulation.id = await Api.createSimulation(simulation);

      commit("ADD_SIMULATION", simulation);

      // Add the new simulation to filtered ones if transactions are filtered
      if (!rootState.transactions.filters.simulation.includes(-1)) {
        commit(
          "transactions/TOGGLE_FILTER",
          {
            key: "simulation",
            value: simulation.id,
            checked: true,
          },
          { root: true }
        );
      }

      Vue.notify({
        group: "app",
        title: "Simulation ajoutée.",
        type: "success",
      });

      return simulation;
    },
    async delete({ commit }, simulation: Simulation) {
      await Api.deleteSimulation(simulation);
      commit("REMOVE_SIMULATION", simulation.id);
      Vue.notify({
        group: "app",
        title:
          "Simulation supprimée. Elle n'était associée à aucune transacation.",
        type: "success",
      });
    },
    async update({ commit }, simulation: Simulation) {
      await Api.updateSimulation(simulation);
      commit("ADD_SIMULATION", simulation);
      Vue.notify({
        group: "app",
        title: "Simulation mise à jour.",
        type: "success",
      });
    },
  },
  mutations: {
    ADD_SIMULATION(state, simulation: Simulation) {
      Vue.set(state.simulations, simulation?.id || 0, simulation);
    },
    REMOVE_SIMULATION(state, id: number) {
      Vue.delete(state.simulations, id);
    },
    SET_SIMULATIONS(state, simulations: { [key: number]: Simulation }) {
      state.simulations = simulations;
    },
  },
} as Module<SimulationsState, RootState>;
