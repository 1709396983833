import { Module } from "vuex";
import { DashboardState, RootState } from "@/types/states";
import { Moment } from "moment";
import { momentFr as moment } from "@/utils/time";
import { Transaction } from "@/types/models";

const endOfMonths: Moment[] = [];

for (let i = 0; i < 13; i++) {
  endOfMonths.push(moment().subtract(i, "months").endOf("month"));
}

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {
    last12MonthBalances(state, getters, rootState, rootGetters) {
      return [...endOfMonths]
        .reverse()
        .map((date: Moment) => rootGetters["transactions/balanceAt"](date))
        .reduce(
          (chartData, balance) => {
            chartData.labels.push(balance.date.format("MMMM YYYY"));
            chartData.data.push(balance.balance);
            return chartData;
          },
          { labels: [], data: [] }
        );
    },
    last12MonthIncomes(state, getters, rootState: RootState) {
      return [...endOfMonths]
        .map((date: Moment) =>
          Object.values(rootState.transactions.transactions).filter(
            (transaction: Transaction) => {
              return (
                transaction.amount > 0 &&
                transaction.shouldPaidAt.isBetween(
                  date.clone().startOf("month"),
                  date,
                  "month",
                  "[]"
                )
              );
            }
          )
        )
        .reverse()
        .reduce(
          (chartData, transactions: Transaction[], index: number) => {
            chartData.labels.push(endOfMonths[12 - index].format("MMMM YYYY"));
            chartData.data.push(
              transactions.reduce(
                (sum, transaction) => sum + transaction.amount,
                0
              )
            );
            return chartData;
          },
          { labels: [], data: [] } as { labels: string[]; data: number[] }
        );
    },
    last12MonthOutcomes(state, getters, rootState: RootState) {
      return [...endOfMonths]
        .map((date: Moment) =>
          Object.values(rootState.transactions.transactions).filter(
            (transaction: Transaction) => {
              return (
                transaction.amount < 0 &&
                transaction.shouldPaidAt.isBetween(
                  date.clone().startOf("month"),
                  date,
                  "month",
                  "[]"
                )
              );
            }
          )
        )
        .reverse()
        .reduce(
          (chartData, transactions: Transaction[], index: number) => {
            chartData.labels.push(endOfMonths[12 - index].format("MMMM YYYY"));
            chartData.data.push(
              transactions.reduce(
                (sum, transaction) => sum - transaction.amount,
                0
              )
            );
            return chartData;
          },
          { labels: [], data: [] } as { labels: string[]; data: number[] }
        );
    },
  },
} as Module<DashboardState, RootState>;
