import { Module } from "vuex";
import { RootState, TagState } from "@/types/states";
import { Api } from "@/services/api";
import { Tag, TagColor } from "@/types/models";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    tags: {},
    colors: {},
  },
  actions: {
    async create({ commit, getters, state }, tag: Tag) {
      tag.colorId =
        tag.colorId ||
        getters.availableColors[0]?.id ||
        Object.values(state.colors)[0].id;
      tag.id = await Api.createTag(tag);

      commit("ADD_TAG", tag);

      Vue.notify({
        group: "app",
        title: "Catégorie ajoutée.",
        type: "success",
      });

      return tag;
    },
    async delete({ commit }, tag: Tag) {
      await Api.deleteTag(tag);
      commit("REMOVE_TAG", tag.id);
      Vue.notify({
        group: "app",
        title:
          "Catégorie supprimée. Elle n'était associée à aucune transacation.",
        type: "success",
      });
    },
    async loadColors({ commit }) {
      commit("SET_COLORS", await Api.getTagColors());
    },
    async loadTags({ commit }) {
      commit("SET_TAGS", await Api.getTags());
    },
    async update({ commit }, tag: Tag) {
      await Api.updateTag(tag);
      commit("ADD_TAG", tag);
      Vue.notify({
        group: "app",
        title: "Catégorie mise à jour.",
        type: "success",
      });
    },
  },
  mutations: {
    ADD_TAG(state, tag: Tag) {
      Vue.set(state.tags, tag?.id || 0, tag);
    },
    REMOVE_TAG(state, id: number) {
      Vue.delete(state.tags, id);
    },
    SET_COLORS(state, colors: { [key: number]: TagColor }) {
      state.colors = colors;
    },
    SET_TAGS(state, tags: { [key: number]: Tag }) {
      state.tags = tags;
    },
  },
  getters: {
    availableColors(state) {
      return Object.values(state.colors).filter(
        (color: TagColor) =>
          !Object.values(state.tags).find(
            (tag: Tag) => tag.colorId === color.id
          )
      );
    },
    tagsWithColor(state) {
      return Object.values(state.tags).map((t: Tag) =>
        Object.assign({}, t, {
          color: state.colors[t.colorId || 0].color || "",
        })
      );
    },
  },
} as Module<TagState, RootState>;
