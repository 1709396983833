import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const secureLS = ["preprod", "production", "local"].includes(
  process.env.NODE_ENV || ""
);

const ls = new SecureLS({
  encodingType: secureLS ? "rc4" : "",
  encryptionSecret: process.env.VUE_APP_LS_SECRET || "M0n3y1$K1n9",
  isCompression: secureLS,
});

export default createPersistedState({
  key: "state",
  paths: [
    "auth",
    "bank.disabledAccounts",
    "scenarios",
    "tags",
    "transactions.countSimulation",
    "transactions.sortCriteria",
    "transactions.timelineScale",
  ],
  storage: {
    getItem: (key) => {
      try {
        return ls.get(key);
      } catch (ignore) {
        return null;
      }
    },
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});
