import moment, { Moment } from "moment";
moment.locale("fr");
export const momentFr = moment;
export const now = (): Moment => moment().clone();
export const toSfDate = (date: Moment | undefined): string | undefined => {
  if (!date) return undefined;
  return date.format("YYYY-MM-DD[T]HH:mm:ss");
};

export const dayOfWeek = (date: Moment): string =>
  new Intl.DateTimeFormat(navigator.language, { weekday: "short" }).format(
    date.toDate()
  );

export const allowedDateFormats = [
  "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ",
  "DD/MM/YYYY",
  "D/M/YYYY",
  "DD.MM.YYYY",
  "D.M.YYYY",
  "DD. MM. YYYY",
  "D. M. YYYY",
];

export const tryParse = (raw: string): Moment | null => {
  const parsed = moment(raw, allowedDateFormats);
  if (parsed.isValid()) return parsed;

  return null;
};
