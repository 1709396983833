import { Module } from "vuex";
import Vue from "vue";
import _uniqBy from "lodash.uniqby";
import { RootState, AuthState } from "@/types/states";
import Axios from "@/services/axios";
import { Payment, Plugin, Plugins, User } from "@/types/models";
import { momentFr as moment } from "@/utils/time";
import {
  Balance,
  Company as ApiCompany,
  Payment as ApiPayment,
} from "@/types/api";
import { DailyBalance } from "@/types/timeline";
import { Api } from "@/services/api";
import { Company } from "@/types/models";

export default {
  namespaced: true,
  state: {
    companyId: null,
    token: "",
    user: null,
    companies: [],
  },
  actions: {
    async changeCompany({ commit, dispatch }, companyId: number) {
      commit("SET_COMPANY_ID", companyId);
      await dispatch("loadProfile");
      await dispatch("transactions/reset", null, { root: true });
    },
    async login({ commit, dispatch }, { username, password }) {
      const response = await Axios.post("login_check", { username, password });
      if (response) {
        commit("SET_TOKEN", response.data.token);
        dispatch("loadProfile");
      }
    },
    logout({ commit }, { expired = false } = {}) {
      commit("SET_TOKEN", null);
      commit("transactions/CLEAR_TRANSACTIONS", null, { root: true });

      if (expired) {
        commit("errors/SET", "sessionExpired", { root: true });
      }
    },
    async loadProfile({ commit, dispatch, getters }) {
      if (!getters.isAuthenticated) return;

      const profile = await Api.getUserProfile();
      commit("SET_USER", profile);

      const companies = profile.companies.map((company) => {
        const {
          id,
          nom: name,
          addresse: address,
          codePostal: zip,
          ville: city,
          siret,
          telephone: phoneNumber,
          myDsoLogin,
          myDsoUrl,
        } = company;

        const plugins = {} as Record<Plugins, Plugin>;

        const payments: Payment[] | undefined = company.payments
          ?.map((payment: ApiPayment) => ({
            id: payment.id,
            reference: payment.reference,
            date: moment(payment.date.date),
            amount: payment.planPrix,
            planId: payment.planId,
          }))
          .sort((a, b) => b.date.diff(a.date));

        if (myDsoLogin && myDsoUrl)
          plugins.mydso = { credentials: { login: myDsoLogin, url: myDsoUrl } };

        return {
          id,
          name,
          address,
          zip,
          city,
          siret,
          phoneNumber,
          lastPayment: payments ? payments[0] : null,
          currentPlanId: payments ? payments[0].planId : null,
          payments: payments || [],
          plugins,
        } as Company;
      });

      commit("SET_COMPANIES", companies);

      const {
        id,
        devise: currency,
        historyBalancesApi,
        decouvert,
        pointMort,
        abonnementPaye: subscriptionPaid,
        abonnementGratuit: subscriptionFree,
        abonnementDatefin,
        simulations,
      } = (profile.companies as ApiCompany[]).find(
        (c) => c.id === getters.company.id
      ) as ApiCompany;

      commit("SET_COMPANY_ID", id);

      commit("transactions/SET_CURRENCY", currency, { root: true });
      commit(
        "billing/SET_BILLING_STATUS",
        {
          subscriptionPaid,
          subscriptionFree,
          subscriptionEndDate: moment(abonnementDatefin?.date),
        },
        { root: true }
      );
      commit(
        "settings/SET_SETTINGS",
        {
          lowBalanceAlert: pointMort,
          maxOverdraft: decouvert,
        },
        { root: true }
      );
      commit(
        "simulations/SET_SIMULATIONS",
        simulations?.reduce(
          (toStore, simulation) =>
            Object.assign(toStore, {
              [simulation.id]: { id: simulation.id, label: simulation.libelle },
            }),
          {}
        ) || {},
        { root: true }
      );

      const balancesUnique = _uniqBy(
        historyBalancesApi.reverse(),
        (b: Balance) => moment(b.updatedAt.date).startOf("day").unix()
      )
        .map(
          (b) =>
            ({
              date: moment(b.updatedAt.date).startOf("day"),
              balance: b.solde,
            } as DailyBalance)
        )
        .sort((a, b) => a.date.diff(b.date));
      commit("transactions/SET_DAILY_BALANCES", balancesUnique, { root: true });

      dispatch("tags/loadColors", null, { root: true });
      dispatch("tags/loadTags", null, { root: true });
    },
    async register(
      { commit, dispatch },
      { email, password, firstname, lastname, company }
    ) {
      await Api.register({ email, password, lastname, firstname });
      await dispatch("login", { username: email, password });
      await Api.createCompany(company);
      commit("ui/OPEN_WELCOME_MODAL", null, { root: true });
      await dispatch("loadProfile");
    },
    async updatePassword({ commit, state }, { oldPassword, newPassword }) {
      commit("errors/RESET", "invalidCredentials", { root: true });

      const response = await Axios.post("login_check", {
        username: state.user?.email,
        password: oldPassword,
      });
      if (response) {
        await Api.updateUserPassword(newPassword);
        Vue.notify({
          group: "app",
          title: "Mot de passe mis à jour",
          type: "success",
        });
      }
    },
    async updateProfile({ dispatch }, user: User) {
      await Api.updateUserProfile(user);
      await dispatch("loadProfile");
      Vue.notify({
        group: "app",
        title: "Profil mis à jour",
        type: "success",
      });
    },
    async updateCompany({ dispatch }, company: Company) {
      await Api.updateCompanyProfile(company);
      await dispatch("loadProfile");
      Vue.notify({
        group: "app",
        title: "Entreprise mise à jour",
        type: "success",
      });
    },
  },
  mutations: {
    SET_COMPANIES(state, companies: Company[]) {
      state.companies = companies;
    },
    SET_COMPANY_ID(state, companyId) {
      state.companyId = companyId;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, { email, firstName, lastName }: User) {
      state.user = { email, firstName, lastName };
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token?.length,
    company: (state) =>
      state.companies.find((c) => c.id === state.companyId) ||
      state.companies[0],
    hasPlugin: (_, getters) => (pluginName: Plugins) =>
      !!getters.company.plugins[pluginName],
    plugins: (_, getters) => Object.keys(getters.company.plugins),
  },
} as Module<AuthState, RootState>;
