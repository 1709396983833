import { AxiosRequestConfig } from "axios";
import store from "@/store";

export const tokenInjector = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  if (store.state.auth.token) {
    if (!config.headers) config.headers = {};
    config.headers["Authorization"] = "Bearer " + store.state.auth.token;
  }

  return config;
};
