import { Plugins, Transaction } from "@/types/models";

export const transactionsBalanceReducer = (
  previous: number,
  transaction: Transaction
): number => previous + transaction.amount;

export const roundPower10 = (number: number): number => {
  const powerOf10 = Math.floor(Math.log10(Math.abs(number)));

  return Math.round(number / 10 ** powerOf10) * 10 ** powerOf10;
};

export const getLockerLabel = (lock: Plugins | string | undefined): string => {
  switch (lock) {
    case "mydso":
      return "My DSO Manager";
    default:
      return "";
  }
};
