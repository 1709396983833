import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";

const publicRoutesNames: string[] = [];
const anonymousOnlyRouteNames = [
  "Login",
  "Register",
  "PasswordForgotten",
  "PasswordReset",
];

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
  },
  {
    path: "/settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
    children: [
      {
        name: "Settings",
        path: "",
        beforeEnter: (to, from, next) => next({ name: "Settings.App" }),
      },
      {
        name: "Settings.Profile",
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "settings.profile" */ "@/views/Settings/Profile.vue"
          ),
      },
      {
        name: "Settings.App",
        path: "app",
        component: () =>
          import(
            /* webpackChunkName: "settings.app" */ "@/views/Settings/App.vue"
          ),
      },
      {
        name: "Settings.Billing",
        path: "billing",
        component: () =>
          import(
            /* webpackChunkName: "settings.billing" */ "@/views/Settings/Billing.vue"
          ),
      },
      {
        name: "Settings.Plugins",
        path: "plugins",
        component: () =>
          import(
            /* webpackChunkName: "settings.plugins" */ "@/views/Settings/Plugins.vue"
          ),
      },
    ],
  },
  {
    path: "/return/password",
    name: "PasswordReset",
    component: () =>
      import(
        /* webpackChunkName: "password.reset" */ "@/views/PasswordReset.vue"
      ),
  },
  {
    path: "/return",
    name: "Webhooks",
    component: () =>
      import(/* webpackChunkName: "webhook" */ "@/views/Webhook.vue"),
    children: [
      {
        path: "bank",
        name: "Webhooks.Bank",
        component: () =>
          import(
            /* webpackChunkName: "webhook.bank" */ "@/views/Webhooks/Bank.vue"
          ),
      },
      {
        path: "bi",
        name: "Webhooks.BI",
        component: () =>
          import(
            /* webpackChunkName: "webhook.bi" */ "@/views/Webhooks/BI.vue"
          ),
      },
      {
        path: "stripe",
        name: "Webhooks.Stripe",
        component: () =>
          import(
            /* webpackChunkName: "webhook.stripe" */ "@/views/Webhooks/Stripe.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
  },
  {
    path: "/password",
    name: "PasswordForgotten",
    component: () =>
      import(
        /* webpackChunkName: "password.forgotten" */ "@/views/PasswordForgotten.vue"
      ),
  },
  {
    path: "/reschedule",
    name: "Reschedule",
    component: () =>
      import(/* webpackChunkName: "reschedule" */ "@/views/Reschedule.vue"),
    beforeEnter: async (to, from, next) => {
      await store.dispatch("transactions/init");

      return !store.getters["transactions/hasPastTransactions"]
        ? next({ name: "Home" })
        : next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.name) return next();

  if (
    !publicRoutesNames.includes(to.name) &&
    !anonymousOnlyRouteNames.includes(to.name) &&
    !store.getters["auth/isAuthenticated"]
  ) {
    return next({ name: "Login" });
  }

  if (
    store.getters["billing/isSubscriptionExpired"] &&
    to.name !== "Settings.Billing"
  )
    return next({ name: "Settings.Billing" });

  if (
    anonymousOnlyRouteNames.includes(to.name) &&
    store.getters["auth/isAuthenticated"]
  ) {
    return next({ name: "Home" });
  }

  return next();
});

export default router;
