import { Module } from "vuex";
import { RootState, ErrorsState } from "@/types/states";
import { Vue } from "vue-property-decorator";

export default {
  namespaced: true,
  state: {
    accountDisabled: false,
    biError: false,
    invalidCredentials: false,
    stripeError: false,
    networkError: false,
    sessionExpired: false,
  },
  mutations: {
    SET(state, errorName: string) {
      if (errorName in state) {
        Vue.set(state, errorName, true);
      } else if (process.env.NODE_ENV === "development") {
        console.warn(`Trying to set unknown error ${errorName}`);
      }
    },
    RESET(state, errorName: string) {
      if (errorName === "*") {
        Object.keys(state).forEach((error) => Vue.set(state, error, false));
      }

      if (errorName in state) {
        Vue.set(state, errorName, false);
      }
    },
  },
} as Module<ErrorsState, RootState>;
